@import 'mixins';
@import 'colors';

html,
body {
	padding: 0;
	margin: 0;
	font-family: LotaGrotesque, -apple-system, BlinkMacSystemFont, Segoe UI,
		Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
		sans-serif;
	// overflow-x: hidden;
	// height: 100%;
}

::selection {
	background: $accent;
	color: $accent-text;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

#container {
	display: flex;
	flex-direction: column;
}

@include above-sm() {
	#container {
		flex-direction: row;
	}
}

#main {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	flex-direction: column;
	// color: #222f4c;
	width: 100%;

	.content {
		text-align: center;
		font-size: 18px;
		// color: #35425f;
	}

	&.formStepper {
		justify-content: space-between;
	}
}

.main--full-height {
	height: 100vh;
	justify-content: space-between !important;
}

.fullWidth {
	width: 100%;
}

button.clear {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

button.styled {
	background-color: $primary;
	color: white;
	border: 0px;
	border-radius: 4px;
	height: 50px;
	font-weight: bolder;
	cursor: pointer;

	&:active {
		background: $primary;
	}
}

button.clearStyle {
	background-color: transparent;
	color: $primary;
	border: 0px;
	border-radius: 4px;
	height: 50px;
	cursor: pointer;

	&:active {
		background: $primary;
	}
}

.form {
	display: grid;
	align-items: center;
	justify-items: center;
	width: 95%;

	h1 {
		font-size: 2.5em;
	}

	label {
		display: block;
		text-align: left;
		margin-bottom: 8px;
		width: 100%;
		color: #5d6984;
		font-weight: 700;
	}

	aside {
		font-size: 14px;
		color: #8791a7;
	}

	div,
	textarea {
		width: 100%;
		margin-bottom: 24px;
		resize: none;
	}

	textarea {
		min-height: 20em;
		resize: none;
	}

	input {
		height: 50px;
		width: 100%;
		border: 1px solid #dbe0e4;
		box-shadow: 0px 2px 6px rgba(0, 66, 89, 0.06);
		border-radius: 4px;

		&[aria-invalid='true'] {
			border: 3px solid red;
		}

		&[aria-invalid='true']:focus {
			border: none;
		}
	}
}

.shaded {
	background-color: #f5f6f9;
	border-radius: 6px;
	padding: 25px 20px;
}

.grey08 {
	color: #5d6984;
}

.button {
	&--primary {
		background-color: $primary;
		color: #fff;
		padding: 17px 80px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		outline-color: $accent;
		font-weight: bolder;
		font-size: 16px;
		font-family: LotaGrotesque !important;
	}

	&--secondary {
		width: 100%;
		background: $blue-2;
		color: $primary;
		border-radius: 4px;
		height: 50px;
		border: 0px solid black;
		cursor: pointer;

		&:active {
			background: $blue-1;
		}

		font-family: LotaGrotesque !important;
	}

	&--tertiary {
		width: 100%;
		background: $white;
		color: $blue-1;
		border-radius: 4px;
		height: 50px;
		box-shadow: 0px 2px 6px rgba(0, 66, 89, 0.29);
		border: 0px solid black;
		cursor: pointer;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;

		&:active {
			background: $light-gray;
		}

		font-family: LotaGrotesque !important;
	}

	&__icon {
		margin-left: 18px;
	}

	&--clear {
		padding: 1rem 2rem;
		margin: 0;
		text-decoration: none;
		font-family: sans-serif;
		font-size: 1rem;
		cursor: pointer;
		text-align: center;
		background: none;
		margin-top: 5px;
		border: 1px solid black;

		@include above-sm() {
			& {
				border: none;
			}
		}
	}

	&--gray {
		background-color: $gray-8;
		color: #fff;
		padding: 10px 20px;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		outline-color: $accent;
		font-weight: bolder;
		font-size: 16px;
		font-family: LotaGrotesque !important;
	}
}