.app {
  display: flex;
  padding: 0;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
  }
}

@font-face {
  font-family: "LotaGrotesque";
  src: url("fonts/LotaGrotesqueRegular.otf") format("opentype");
  font-display: swap;
}